<template>
    <div class="modal fade" id="addUsrWallet">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content" ref="addUsrWallet">
                <div class="modal-header">
                    <h5 class="modal-title">Add wallet</h5>
                    <button type="button" class="close" data-dismiss="modal"><span>&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label class="col-form-label">
                            Wallet Type
                        </label>
                        <select class="form-control" v-model="address_type">
                            <option value="">Select wallet type</option>
                            <option :value="key" v-for="(val, key, idx) in $globalFunc.cryptoList" :key="idx">
                                {{val}} - {{key}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label class="col-form-label">
                            Enter a wallet address
                        </label>
                        <input type="text" v-model="address" class="form-control" placeholder="-----------">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-info" data-dismiss="modal">Close</button>
                    <button type="button" @click="addUWallet" class="btn btn-outline-primary">
                        <i class="fa fa-save"></i> Save
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "addUserWallet",
    data(){
        return {
            address_type: '',
            address: ''
        }
    },
    methods: {
        async addUWallet(){
            if(this.address_type && this.address){
                const loader = this.$loading.show({container: this.$refs.addUsrWallet});
                let response = await this.$store.dispatch('user/addUserWallet', {
                    wallet_type: this.address_type,
                    wallet_address: this.address
                });
                if(response.status){
                    toastr.success("Operation successful");
                    this.address_type = '';
                    this.address = '';
                    $('#addUsrWallet').modal('hide');
                }else{
                    toastr.error(response.message)
                }
                loader.hide();
            }
        }
    }
}
</script>

<style scoped>

</style>