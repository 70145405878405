<template>
    <div class="pt-3" ref="profileTEWallets">
        <div class="settings-form">
            <div class="col-12">
                <div class="float-md-right">
                    <button class="btn btn-info" data-toggle="modal" data-target="#addTEWallet">
                        <i class="fa fa-plus-circle"></i> add wallet
                    </button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-responsive-md" ref="p_t_wallet">
                    <thead>
                    <tr>
                        <th></th>
                        <th>Wallet Type</th>
                        <th>Wallet Address</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-if="wallets.length > 0">
                        <template v-for="(wallet) in wallets">
                            <tr v-for="(address, idx2) in wallet.data.addresses" :key="'t_address_'+idx2">
                                <td>
<!--                                    <strong>{{ idx2+1 }}</strong>-->
                                </td>
                                <td> {{wallet.id}} </td>
                                <td>{{address}}</td>
                                <th>
                                    <a href="javascript:void(0)" class="text-danger" @click="remUWallet(wallet.id, address)">
                                        <i class="fa fa-trash"></i>
                                    </a>
                                </th>
                            </tr>
                        </template>
                    </template>
                    <template v-else>
                        <tr>
                            <td colspan="3">
                                <div class="col-12 text-center mt-4">
                                    <h6 class="text-info">You have no wallet address</h6>
                                </div>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </table>
            </div>
        </div>

        <add-te-wallet/>
    </div>

</template>

<script>
import {mapGetters} from "vuex"
import addTeWallet from "../modals/addTeWallet";

export default {
    name: "profileTEWallets",
    computed: {
        ...mapGetters('user', {
            user: 'getUser'
        }),
        ...mapGetters('wallet', {
            wallets: 'getWallets'
        })
    },
    methods: {
      async remUWallet(w_type, w_address){
          const loader = this.$loading.show({container: this.$refs.p_t_wallet});
          const response = await this.$store.dispatch('wallet/removeWallet', {
              wallet_type: w_type,
              wallet_address: w_address
          });
          if(response.status){
              toastr.success("Wallet address removed")
          }else{
              toastr.warning(response.message)
          }
          loader.hide();
      }
    },
    components: {
        addTeWallet
    },
    mounted() {
        this.$store.dispatch('wallet/fetchWallets')
    }
}
</script>

<style scoped>

</style>