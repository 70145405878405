<template>
    <div class="pt-3" ref="profileSecurity">
        <div class="settings-form">
            <h4 class="text-primary">Update Password</h4>
            <form @submit.prevent="updatePassword">
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label>New Password</label>
                        <input type="password" minlength="8" placeholder="********" class="form-control" v-model="password">
                    </div>
                    <div class="form-group col-md-6">
                        <label>Retype Password</label>
                        <input type="password" minlength="8" placeholder="********" class="form-control" v-model="re_password">
                    </div>
                </div>
                <button class="btn btn-primary" type="submit">Update</button>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: "profileSecurity",
    data(){
        return {
            password: '',
            re_password: ''
        }
    },
    methods: {
        async updatePassword(){
            if(!this.password){
                return toastr.warning("Enter a valid password", "Oops!")
            }
            if(this.password !== this.re_password){
                return toastr.warning("Passwords do not match", "Oops!")
            }
            if(this.password.length < 8){
                return toastr.warning("The password length should be greater than 7 characters", "Oops!")
            }
            const loader = this.$loading.show({container: this.$refs.profileSecurity});
            const response = await this.$store.dispatch('user/updateUserPassword', this.password);
            if(response.status){
                this.password = "";
                this.re_password = "";
                toastr.success("Password updated")
            }else{
                toastr.warning(response.message)
            }
            return loader.hide();
        }
    }
}
</script>

<style scoped>

</style>