<template>
    <div class="content-body">
        <div class="container-fluid">
            <div class="row page-titles mx-0">
                <div class="col-sm-6 p-md-0">
                    <div class="welcome-text">
                        <h4>Hi, {{user.data.name}}</h4>
                        <p class="mb-0">Your TrustEarn Profile</p>
                    </div>
                </div>
            </div>
            <!-- row -->
            <div class="row">
                <div class="col-lg-12">
                    <div class="profile card card-body px-3 pt-3 pb-0">
                        <div class="profile-head">
                            <div class="photo-content">
                                <div class="cover-photo"></div>
                            </div>
                            <div class="profile-info">
                                <div class="profile-photo">
                                    <img src="images/profile/profile.png" class="img-fluid rounded-circle" alt="">
                                </div>
                                <div class="profile-details">
                                    <div class="profile-name px-3 pt-2">
                                        <h4 class="text-primary mb-0">{{user.data.name}}</h4>
                                        <p v-if="user.data.is_admin">
                                            Admin
                                        </p>
                                        <p v-else>
                                            Investor
                                        </p>
                                    </div>
                                    <div class="profile-email px-2 pt-2">
                                        <h4 class="text-muted mb-0">{{user.data.email}}</h4>
                                        <p>Email</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-4">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="profile-statistics">
                                        <div class="text-center">
                                            <div class="row">
                                                <div class="col">
                                                    <h3 class="m-b-0">${{user.data.balance.toLocaleString()}}</h3><span>Balance</span>
                                                </div>
                                                <div class="col">
                                                    <h3 class="m-b-0">0</h3><span>Deposits</span>
                                                </div>
                                                <div class="col">
                                                    <h3 class="m-b-0">0</h3><span>Withdrawals</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-8">
                    <div class="card">
                        <div class="card-body">
                            <div class="profile-tab">
                                <div class="custom-tab-1">
                                    <ul class="nav nav-tabs">
                                        <li class="nav-item">
                                            <a href="#about-me" data-toggle="tab" class="nav-link active show">About Me</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="#security" data-toggle="tab" class="nav-link">Security</a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="#usr-wallet" data-toggle="tab" class="nav-link">Wallets</a>
                                        </li>
                                        <li class="nav-item" v-if="user.data.is_admin">
                                            <a href="#admin-wallet" data-toggle="tab" class="nav-link">TE Wallets</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content">
                                        <div id="about-me" class="tab-pane fade active show">
                                            <profile-about/>
                                        </div>
                                        <div id="security" class="tab-pane fade">
                                            <profile-security/>
                                        </div>
                                        <div id="usr-wallet" class="tab-pane fade">
                                            <profile-wallets/>
                                        </div>
                                        <div id="admin-wallet" class="tab-pane fade" v-if="user.data.is_admin">
                                            <profile-t-e-wallets/>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import profileAbout from "../../components/dashboard/profile/profileAbout";
import profileSecurity from "../../components/dashboard/profile/profileSecurity";
import profileWallets from "../../components/dashboard/profile/profileWallets";
import profileTEWallets from "../../components/dashboard/profile/profileTEWallets";

export default {
    name: "Profile",
    computed: {
        ...mapGetters('user', {
            user: 'getUser'
        })
    },
    components: {
        profileAbout,
        profileSecurity,
        profileWallets,
        profileTEWallets
    }
}
</script>

<style scoped>

</style>