<template>
    <div class="pt-3" ref="profileAbout">
        <div class="settings-form">
            <div class="col-12 alert alert-warning">
                <h6 class="text-primary">your referral link</h6>
                <a :href="'https://trustearns.com/register/'+user.id" target="_blank">
                    https://trustearns.com/register/{{user.id}}
                </a>
            </div>
            <h4 class="text-primary">Account Profile</h4>
            <form @submit.prevent="updateProfile">
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label>Username</label>
                        <input type="text" placeholder="username" class="form-control" v-model="tmp_user.data.name">
                    </div>
                    <div class="form-group col-md-6">
                        <label>Email (disabled)</label>
                        <input type="email" placeholder="Email" class="form-control" :value="tmp_user.data.email" disabled>
                    </div>
                </div>
                <div class="form-group">
                    <label class="mb-1"><strong>Country</strong></label>
                    <country-select :autocomplete="true" style="height:40px" class="form-control" v-model="tmp_user.data.country" :country="tmp_user.data.country" />
                </div>
                <div class="form-group">
                    <label class="mb-1"><strong>Region</strong></label>
                    <region-select :autocomplete="true" :regionName="true" style="height:40px" class="form-control" v-model="tmp_user.data.region" :country="tmp_user.data.country" :region="tmp_user.data.region" />
                </div>
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <label>Phone</label>
                        <input type="tel" class="form-control" v-model="tmp_user.data.phone">
                    </div>
                </div>
                <button class="btn btn-primary" type="submit">Update</button>
            </form>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import User from "../../../models/user";

export default {
    name: "profileAbout",
    data(){
        return {
            tmp_user: new User()
        }
    },
    computed: {
        ...mapGetters('user', {
            user: 'getUser'
        })
    },
    watch: {
        user(){
            this.loadUser()
        }
    },
    methods: {
        async updateProfile(){
            const loader = this.$loading.show({container: this.$refs.profileAbout});
            const {name, country, region, phone} = this.tmp_user.data;
            const response = await this.$store.dispatch('user/updateUser', {
                userId: this.tmp_user.id,
                userData: {name,country, region, phone}
            });
            if(response.status){
                toastr.success("Profile updated")
            }else{
                toastr.error(response.message)
            }
            loader.hide();
        },
        loadUser(){
            const tmp_usr = this.$store.getters['user/getUser'];
            this.tmp_user = new User(tmp_usr.data, tmp_usr.id)
        }
    },
    mounted() {
        this.$store.dispatch('user/initUser', this.$store.getters['user/getUser'].id);
        this.loadUser()
    }
}
</script>

<style scoped>

</style>